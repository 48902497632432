.AUREO__navbar{
   display: flex;
   justify-content: space-between;
   align-items: center;
   padding: 2rem 6rem; 
}

.AUREO__navbar-links{
    flex: 1;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.AUREO__navbar-links_logo{
    margin-right: 2rem;
}

.AUREO__navbar-links_logo img{
    /*width: 62.56px;
    height: 16.08px;*/
    width: 148.56px;
    height: 127.08px;
}

.AUREO__navbar-links_container{
    display: flex;
    flex-direction: row;
}

.AUREO__navbar-sign{
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.AUREO__navbar-links_container p,
.AUREO__navbar-sign p,
.AUREO__navbar-menu_container p{
    color: #fff;
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 18px;
    line-height: 25px;
    text-transform: capitalize;

    margin: 0 1rem;
    cursor: pointer;
}


.AUREO__navbar-sign button,
.AUREO__navbar-menu_container button {
    padding: 0.5rem 1rem;
    color: #000;
    background: #ffc800;
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 18px;
    line-height: 25px;
    border: none;
    outline: none;
    cursor: pointer;
    border-radius: 5px;
}

.AUREO__navbar-menu{
    margin-left: 1rem;

    display: none;
    position: relative;
}

.AUREO__navbar-menu svg{
    cursor: pointer;
}

.AUREO__navbar-menu_container{
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    flex-direction: column;

    text-align: end;
    background: var(--color-footer);
    padding: 2rem;
    position: absolute;
    top: 40px;
    right: 0;
    margin-top: 1rem;
    min-width: 210px;
    border-radius: 5px;
    box-shadow: 0px 0px 5px rgba(0,0,0,0.2);
    z-index: 99;
}

.AUREO__navbar-menu_container p{
    margin: 1rem 0;
}

.AUREO__navbar-menu_container-links-sign{
    display: none;
}

@media screen and (max-width: 1050px){
    .AUREO__navbar-links_container{
        display: none;
    }
    .AUREO__navbar-menu{
        display: flex;
    }
}

@media screen and (max-width: 700px) {
    .AUREO__navbar {
        padding: 2rem 4rem;
    }
}

@media screen and (max-width: 550px) {
    .AUREO__navbar {
        padding: 2rem;
    }

    .AUREO__navbar-sign {
        display: none;
    }

    .AUREO__navbar-menu_container {
        top: 20px;
    }

    .AUREO__navbar-menu_container-links-sign {
        display: block;
    }
}