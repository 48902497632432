.AUREO__blog-container_article{
    width: 100%;
    height: 100%;

    display: flex;
    flex-direction: column;
    background: var(--color-footer);
}

.AUREO__blog-container_article-image{
    width: 100%;
    height: 100%;
    background: var(--color-bg);
}

.AUREO__blog-container_article-image img{
    width: 100%;
    height: 100%;
}

.AUREO__blog-container_article-content{
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    padding: 1rem 1.5rem;
    height: 100%;
}

.AUREO__blog-container_article-content p{
    font-family: var(--font-family);
    /*font-weight: 700;
    font-size: 11.65px;
    line-height: 35px;*/
    /*color: #fff;*/
}

.AUREO__blog-container_article-content h3{
    font-family: var(--font-family);
    font-weight: 800;
    font-size: 25.11px;
    line-height: 30.30px;

    color: #fff;
    /*margin-bottom: 5rem;*/
    cursor:pointer;
}

.AUREO__blog-container_article-content p:last-child{
    cursor: pointer;
}

@media  screen and (max-width: 550px) {
    .AUREO__blog-container_article-content h3{
        font-size: 18px;
        line-height: 25px;

    }
}